<template>
  <v-app>
    <v-container class="my-6 mx-auto scoreTables">
      <v-card>
        <div class="card-header-padding pb-0">
          <h5 class="font-weight-bold text-h5 text-typo mb-0">
            Ангийн өдрийн ирц бүртгэx
          </h5>
          <v-row class="mt-0">
            <v-col>
              <v-select
                :disabled="!classIrzUnlockAccepted"
                style="background-color: #f8bbd0"
                class="px-2"
                @change="forceRerender()"
                v-if="loading == false"
                label="Анги сонгох"
                :items="classGroups"
                item-text="name2"
                item-value="id"
                return-object
                v-model="selectedProgram"
              ></v-select>
            </v-col>
            <v-col v-if="selectedMonth">
              <v-select
                @change="forceRerender()"
                label="Өдөр сонгох"
                :items="days"
                v-model="selectedDay"
              ></v-select>
            </v-col>
            <v-row class="ml-4">
              <small
                @click="
                  selectedMonth = month;
                  forceRerender();
                "
                small
                style="
                  cursor: pointer;
                  background: #dadada;
                  color: #000 !important;
                  height: 25px;
                "
                class="border-radius-sm text-xxs me-1 shadow-none font-weight-bold px-3 py-1 mt-12"
                :class="
                  selectedMonth == month
                    ? 'bg-gradient-warning white--text'
                    : ''
                "
                :style="selectedMonth == month ? 'color: white' : ''"
                elevation="0"
                :ripple="false"
                v-for="(month, mindex) in [1, 2, 3, 4, 5, 6, 9, 10, 11, 12]"
                :key="'month-' + mindex"
                >{{ month }}</small
              >
            </v-row>
            <v-col>
              <v-card
                color="#F8F9FA"
                class="d-flex align-center justify-center"
                height="100%"
              >
                <h2 class="text-center red--text">
                  {{ selectedMonth }}/{{ selectedDay ? selectedDay : "-" }}
                </h2>
              </v-card>
            </v-col>
          </v-row>
          <div v-if="classIrzUnlock && classIrzUnlock.required">
            <v-btn
              @click="
                showJijuurTeacherUnlockDialog = !showJijuurTeacherUnlockDialog
              "
              small
              class="bg-gradient-danger mt-2"
              dark
              elevation="0"
              >Жижүүр багш</v-btn
            >
            <p class="red--text" style="font-size: 9pt">
              сүүлд нэвтэрсэн: {{ classIrzUnlock.lastUsageDate }}
            </p>
          </div>
        </div>
        <AttendanceLessonByStudents
          class="pt-0"
          v-if="
            students &&
            students.length > 0 &&
            selectedMonth &&
            selectedDay &&
            renderComponent &&
            isWeekend == false
          "
          v-bind="$attrs"
          :selectedProgram="selectedProgram"
          :selectedXDay="selectedDay"
          :selectedMonth="selectedMonth"
          :students="students"
          :forClass="true"
        ></AttendanceLessonByStudents>
        <v-container v-else class="text-center mt-16 red--text">
          <h2>Амралтийн өдөр сонгогдсон байна</h2>
        </v-container>
      </v-card>
    </v-container>

    <v-dialog v-model="showJijuurTeacherUnlockDialog" max-width="400">
      <v-card class="py-4 px-2">
        <v-card-title class="headline">
          <span class="text-typo text-h6"
            >Та жижүүр багшаар ангийн өдрийн ирцийг бүртгэx
            <span class="red--text">нууц үгээ</span> xийнэ үү.</span
          >
        </v-card-title>
        <v-card-text>
          <v-text-field
            autofocus
            v-model="classIrzUnlockPassInput"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="
              showJijuurTeacherUnlockDialog = !showJijuurTeacherUnlockDialog
            "
            >Цуцлаx</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            class="bg-gradient-success text-capitalize"
            dark
            @click="_unlockClassIrz"
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import AttendanceLessonByStudents from "@/views/Pages/Irz/AttendanceLessonByStudents";
// import StudentsByClassGroup from "./Widgets/StudentsByClassGroup"
const fb = require("@/firebaseConfig.js");
export default {
  components: {
    AttendanceLessonByStudents,
    //StudentsByClassGroup
  },
  data: () => ({
    classIrzUnlockAccepted: false,
    classIrzUnlockPassInput: null,
    showJijuurTeacherUnlockDialog: false,
    classIrzUnlock: null,
    currenSelectedLesson: null,
    tabClassGroups: null,
    selectedMonth: null,
    query: null,
    path: null,
    headerNames: null,
    bodyNames: null,
    students: null,
    lessons: null,
    xlessons: null,
    days: null,
    selectedDay: null,
    classGroups: null,
    selectedProgram: null,
    loading: false,
    renderComponent: true,
    isWeekend: false,
  }),
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    yyschool() {
      return fb.yyschool;
    },
    headers2() {
      var list = [];
      list.push({
        text: "Нэр",
        align: "end",
        value: "index",
        sortable: true,
        width: "1%",
      });
      list.push({
        text: "Нэр",
        align: "center",
        value: "firstName",
        sortable: true,
        width: "30%",
      });
      for (var i = 0; i < 31; i++) {
        list.push({
          text: i + 1,
          align: "center",
          value: "val-" + (i + 1),
          sortable: true,
        });
      }
      return list;
    },
    body2() {
      var list = [];
      var row;
      for (var k = 0; k < 300; k++) {
        row = {
          index: k + 1,
          firstName: "User-" + (k + 1),
        };
        for (var i = 1; i < 31; i++) {
          var xx = "val-" + (i + 1).toString();
          var tmp = {};
          tmp[xx] = i + 1;
          row[xx] = i + 1;
        }
        list.push(row);
      }

      return list;
    },
  },
  watch: {
    async selectedProgram(val) {
      if (val) {
        this.userData.school.ref
          .collection("students-" + this.userData.school.currentYear)
          .where("STUDENT_GROUP_ID", "==", val.STUDENT_GROUP_ID)
          .orderBy("firstName", "asc")
          .onSnapshot((docs) => {
            this.students = [];
            docs.forEach(async (doc) => {
              let student = doc.data();
              student.ref = doc.ref;
              student.id = doc.id;
              if (!student.moved) {
                await val.ref
                  .collection("attendances-" + this.userData.school.currentYear)
                  .where("studentId", "==", student.id)
                  .onSnapshot((docs) => {
                    student.attendances = [];
                    student.studentsNotes = [];
                    docs.forEach((doc) => {
                      if (doc.exists) {
                        let x = doc.data();
                        x.ref = doc.ref;
                        x.id = doc.id;
                        x.ref.update({
                          notedBy: this.userData.ref,
                          notedByName: this.userData["DISPLAY_NAME"]
                            ? this.userData["DISPLAY_NAME"]
                            : this.userData["DISPLAY_NAME"]
                            ? this.userData["firstName"]
                            : null,
                        });
                        if (x.id.startsWith("studentsNotes"))
                          student.studentsNotes.push(x);
                        else if (x.daily_attendance == true) {
                          student.attendances.push(x);
                        }
                      }
                    });
                    this.forceRerender();
                  });
                this.students.push(student);
              }
            });
          });
      }
    },
    selectedMonth() {
      var allDays = [];
      this.days = [];
      if (this.selectedMonth == 2) {
        allDays = [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 25, 26, 27, 28,
        ];
      } else if (this.selectedMonth % 2 == 0) {
        allDays = [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
        ];
      } else if (this.selectedMonth % 2 == 1) {
        allDays = [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
        ];
      }
      allDays.forEach((day) => {
        var date = new Date(
          new Date().getFullYear +
            "-" +
            (this.selectedMonth < 10
              ? "0" + this.selectedMonth
              : this.selectedMonth) +
            "-" +
            (day < 10 ? "0" + day : day)
        );
        if (date.getDay() != 0 && date.getDay() != 1) {
          this.days.push(day);
        }
      });
    },
    selectedDay() {
      if (this.selectedDay) {
        var date = new Date(
          new Date().getFullYear +
            "-" +
            (this.selectedMonth < 10
              ? "0" + this.selectedMonth
              : this.selectedMonth) +
            "-" +
            (this.selectedDay < 10 ? "0" + this.selectedDay : this.selectedDay)
        );
        if (date.getDay() == 0 || date.getDay() == 1) {
          this.isWeekend = true;
        } else {
          this.isWeekend = false;
        }
      }
    },
    currenSelectedLesson(val) {
      this.path = val.ref.path;
      this.currenSelectedLesson = val;
      this.students = [];
      if (
        this.currenSelectedLesson != null &&
        this.currenSelectedLesson != undefined &&
        this.currenSelectedLesson.classGroups != null &&
        this.currenSelectedLesson.classGroups != undefined
      ) {
        this.currenSelectedLesson.classGroups.forEach((cgroup) => {
          fb.db
            .collection(this.yyschool + "students")
            .where("classGroup-2021", "==", cgroup.classGroupRef)
            .get()
            .then((docs) => {
              this.students[cgroup.classGroupRef.path] = [];
              docs.forEach((doc) => {
                let student = doc.data();
                student.id = doc.id;
                student.ref = doc.ref;
                student.classGroupRef = cgroup.classGroupRef;
                student.attendances = null;
                student.ref
                  .collection("attendances")
                  .doc(this.currenSelectedLesson.id)
                  .collection("attendance")
                  .onSnapshot((docs) => {
                    student.attendances = [];
                    if (!docs.empty) {
                      docs.forEach((doc) => {
                        if (doc.exists) {
                          // console.log(doc.data()); alga bolgoldoo
                          let x = doc.data();
                          x.ref = doc.ref;
                          x.id = doc.id;
                          student.attendances.push(x);
                        }
                      });
                    }
                  });
                this.students[cgroup.classGroupRef.path].push(student);
              });
            });
        });
      }
    },
  },
  created() {
    console.log(this.userData.school.ref.path);
    //TODOX
    //this.path = this.yyschool + "lessons/ePwooaz9bnRiOHpOaif8";
    // this._setupp();
    this.headerNames = [
      {
        text: "xxx",
        align: "end",
        value: "index",
        sortable: true,
        width: "1%",
      },
      {
        text: "",
        value: "avatar",
        width: "1%",
      },

      {
        text: "Нэр",
        align: "start",
        sortable: true,
        value: "firstName",
      },
    ];
    for (var x of Array(12).keys()) {
      x++;
      this.headerNames.push({
        text: x,
        align: "start",
        sortable: true,
        value: x,
      });
    }
    this.bodyNames = ["firstName"];
    this.selectedMonth = new Date().getMonth() + 1;
    if (this.selectedMonth == 7 || this.selectedMonth == 8) {
      this.selectedMonth = 9;
    }
    this.selectedDay = new Date().getDate();

    if (
      this.userData["myClassInfo-" + this.userData.school.currentYear] &&
      this._getMyClassInfo("ACADEMIC_LEVEL") &&
      this._getMyClassInfo("STUDENT_GROUP_ID")
    ) {
      this.userData.school.ref
        .collection("departments-" + this.userData.school.currentYear)
        .doc("department-" + this._getMyClassInfo("ACADEMIC_LEVEL"))
        .collection("programs")
        .doc(this._getMyClassInfo("STUDENT_GROUP_ID").toString())
        .get()
        .then((doc) => {
          let prog = doc.data();
          prog.id = doc.id;
          prog.ref = doc.ref;
          this.selectedProgram = prog;
        });
      console.log(this.userData.school.ref.path);
    }

    this.userData.school.ref
      .collection("_settings")
      .doc("classIrz")
      .onSnapshot((doc) => {
        if (doc.exists) {
          console.log("existing....");
          this.classIrzUnlock = doc.data();
          this.classIrzUnlock.id = doc.id;
          this.classIrzUnlock.ref = doc.ref;
          this.classIrzUnlock.lastUsageDate = null;
          this.classIrzUnlock.ref
            .collection("employees")
            .doc(this.userData.id)
            .onSnapshot((doc) => {
              if (doc.exists) {
                var today = new Date();
                var todayStr =
                  today.getFullYear() +
                  "-" +
                  (today.getMonth() + 1) +
                  "-" +
                  today.getDate();
                this.classIrzUnlock.lastUsageDate = doc.data().usedAt;
                this.classIrzUnlockAccepted =
                  todayStr == this.classIrzUnlock.lastUsageDate;
              } else {
                this.classIrzUnlockAccepted = false;
              }
            });
        } else {
          this.classIrzUnlock = {};
          this.classIrzUnlock.required = false;
          this.classIrzUnlockAccepted = true;
        }
      });

    this.userData.school.ref
      .collection("departments-" + this.userData.school.currentYear)
      .orderBy("index", "desc")
      .get()
      .then((docs) => {
        this.loading = true;
        this.classGroups = [];
        var counter = 0;
        docs.forEach(async (doc) => {
          await doc.ref
            .collection("programs")
            .get()
            .then((docs) => {
              docs.forEach((doc) => {
                counter++;
                let program = doc.data();
                program.ref = doc.ref;
                program.id = doc.id;
                program.name2 = (
                  counter +
                  ". " +
                  program.fullName
                ).toUpperCase();
                if (this.selectedProgram == null)
                  this.selectedProgram = program;
                this.classGroups.push(program);
              });
            });
        });
        this.loading = false;
      });
  },
  methods: {
    _unlockClassIrz() {
      var xxx =
        this.classIrzUnlockPassInput &&
        this.classIrzUnlock.password &&
        this.classIrzUnlockPassInput.length > 0 &&
        this.classIrzUnlockPassInput != "";
      if (xxx) {
        var today = new Date();
        var todayStr =
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate();

        if (this.classIrzUnlockPassInput == this.classIrzUnlock.password) {
          this.classIrzUnlock.ref
            .collection("employees")
            .doc(this.userData.id)
            .set({
              usedAt: todayStr,
              firstName: this.userData.FIRST_NAME
                ? this.userData.FIRST_NAME
                : this.userData.firstName,
              lastName: this.userData.LAST_NAME
                ? this.userData.LAST_NAME
                : this.userData.firstName,
              givenPass: this.classIrzUnlockPassInput,
            })
            .then(() => {
              var today = new Date();
              var todayStr =
                today.getFullYear() +
                "-" +
                (today.getMonth() + 1) +
                "-" +
                today.getDate();

              this.classIrzUnlock.lastUsageDate = todayStr;
              this.classIrzUnlockAccepted =
                todayStr == this.classIrzUnlock.lastUsageDate;
              this.$forceUpdate();
            });
          this.showJijuurTeacherUnlockDialog =
            !this.showJijuurTeacherUnlockDialog;
        } else {
          this.$swal.fire({
            title: "Нууц үг буруу байна!",
            text: "Нууц үгийг менежер тоxируулна.",
          });
        }
      } else {
        this.$swal.fire({
          title: "Та нууц үгээ зөв оруулна уу!",
          text: "Нууц үгийг менежер тоxируулна.",
        });
      }
    },
    _getMyClassInfo(value) {
      return this.userData["myClassInfo-" + this.userData.school.currentYear][
        value
      ];
    },
    async forceRerender() {
      // Remove MyComponent from the DOM
      this.renderComponent = false;

      // Wait for the change to get flushed to the DOM
      await this.$nextTick();

      // Add the component back in
      this.renderComponent = true;
    },
  },
};
</script>
<style>
[data-title2]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}
[data-title2]:after {
  content: attr(data-title2);
  position: absolute;
  bottom: -1.6em;
  left: 65%;
  padding: 4px 4px 4px 8px;
  color: #222;
  white-space: nowrap;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 4px #222;
  -webkit-box-shadow: 0px 0px 4px #222;
  box-shadow: 0px 0px 4px #222;
  background: yellow;
  color: black;
  opacity: 0;
  z-index: 2;
  visibility: hidden;
}
[data-title2] {
  position: relative;
}
</style>
